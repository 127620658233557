import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Blog = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { categories: { eq: "Genel" } } }
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
        ) {
          edges {
            node {
              frontmatter {
                path
                title
                date(formatString: "MMMM DD, YYYY")
                topDetail
                categories
                authorName
                authorImage {
                  childImageSharp {
                    fixed(height: 60) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                featuredImage {
                  childImageSharp {
                    fixed(width: 415, height: 375) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const firstBlog = allMarkdownRemark.edges[0].node.frontmatter
  const { edges } = allMarkdownRemark
  return (
    <Layout>
      <SEO
        title="Blog"
        description="Geleceğin trendleri. Küresel gündem. Yerel dinamikler. Dijital dünya bileşenleri. Pazarlama teknikleri. Ve çok daha fazlası."
      />
      <div className="container blog">
        <div className="blog-header">
          <h1>Son yazılan blog içerikleri.</h1>
          <p>
            Geleceğin trendleri. Küresel gündem. Yerel dinamikler. Dijital dünya
            bileşenleri. Pazarlama teknikleri. Ve çok daha fazlası.
          </p>
        </div>
        <div className="blog-lastest py60">
          <div className="post col-md-12">
            <div className="col-md-5 noPadding">
              <span>Öne Çıkan Makale</span>
              <h2>
                <a href={firstBlog.path}>{firstBlog.title}</a>
              </h2>
              <p>{firstBlog.topDetail}</p>
              <div className="author">
                <Img
                  alt={firstBlog.authorName}
                  fixed={firstBlog.authorImage.childImageSharp.fixed}
                />
                <div className="author-name">
                  <b>{firstBlog.authorName}</b>
                </div>
              </div>
            </div>
            <div className="col-md-5 post-image">
              <a href={firstBlog.path}>
                <Img
                  fixed={firstBlog.featuredImage.childImageSharp.fixed}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </a>
            </div>
          </div>
          {edges.map(item => {
            const { node } = item
            const { frontmatter } = node
            return (
              <div className="post col-md-5">
                <div className="post-image">
                  <a href={frontmatter.path}>
                    <Img
                      fixed={frontmatter.featuredImage.childImageSharp.fixed}
                      objectFit="cover"
                      
                    />
                  </a>
                </div>
                <span>{frontmatter.categories}</span>
                <h2>
                  <a href={frontmatter.path}>{frontmatter.title}</a>
                </h2>
                <p>{frontmatter.topDetail}</p>
                <div className="author">
                  <b>{frontmatter.authorName}</b>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}
export default Blog
